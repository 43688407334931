import React from "react";
import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";

import Button from "./Button.js";

class ButtonGroup extends React.PureComponent{
    render(){
        const { value: selectedValue, options, baseClassName, defaultClassName, primaryClassName, buttonProps } = this.props;


        return (
            <div className="btn-group">
                {
                    _.map(options, ({ text, value }) => {

                        const btnClass = classnames({
                            [baseClassName || ""]: true,
                            [defaultClassName || ""]: value !== selectedValue,
                            [primaryClassName || ""]: value === selectedValue,
                        });

                        return (
                            <Button key={value} type="button" className={btnClass} onClick={this.onClick} onClickData={value} {...buttonProps}>{text}</Button>
                        );
                    })
                }
            </div>
        );
    }

    onClick = (value) => {
        const { onClickData, onChange } = this.props;

        if(!_.isFunction(onChange))
            return;

        onChange(onClickData, value);
    }
}

ButtonGroup.propTypes = {
    onClickData: PropTypes.any,
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    baseClassName: PropTypes.string.isRequired,
    defaultClassName: PropTypes.string.isRequired,
    primaryClassName: PropTypes.string.isRequired,
    buttonProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default ButtonGroup;
