import React from "react"
import _ from 'lodash';
import Layout from "../components/layout"
import { connect } from "react-redux";
import ButtonGroup from "../components/ButtonGroup"
import { Button } from "reactstrap"
import { setCurrentUpdates, saveGuest, clearChanges } from "../store/actions/guestActions"
import { Location } from '@reach/router';

const baseNewGuest = { guestId: 0, name: '', username: '', password: '', side: 1, status: 1 };

const GuestStatusOptions = [
  { text: 'For Sure', value: 1, quickFilterKey: 'guestStatus_forSure', applies: ({ status }) => status === 1  },
  { text: 'Maybe', value: 2, quickFilterKey: 'guestStatus_maybe', applies: ({ status }) => status === 2  },
];

const GuestSideOptions = [
  { text: 'Micaela\'s', value: 1, quickFilterKey: 'guestSide_bride', applies: ({ side }) => side === 1  },
  { text: 'Scott\'s', value: 2, quickFilterKey: 'guestSide_groom', applies: ({ side }) => side === 2  },
];

const RsvpStatusOptions = [
  { text: 'Unspecified', value: 1, quickFilterKey: 'rsvpStatus_unspecified', applies: ({ rsvpStatus }) => rsvpStatus === 1  },
  { text: 'Accepted', value: 2, quickFilterKey: 'rsvpStatus_accepted', applies: ({ rsvpStatus }) => rsvpStatus === 2  },
  { text: 'Rejected', value: 3, quickFilterKey: 'rsvpStatus_rejected', applies: ({ rsvpStatus }) => rsvpStatus === 3  },
];

class GuestsPage extends React.Component {
  constructor(props) {
    super(props);

    const quickFilters = {};

    _.each(GuestStatusOptions.concat(GuestSideOptions), ({ quickFilterKey }) => {
      quickFilters[quickFilterKey] = true;
    });

    this.state = {
      quickFilters: quickFilters,
      simpleView: true,
      filterText: '',
    };
  }

  render() {
      const { quickFilters, simpleView, filterText } = this.state;
      const { guests, currentUpdates } = this.props;

      const filteredGuests = _.filter(guests, (guest) => {
        return _.every(GuestSideOptions.concat(GuestStatusOptions), ({ value, quickFilterKey, applies }) => {
          const isActive = quickFilters[quickFilterKey] === true;

          if(!isActive && applies(guest))
            return false;

          return _.includes(_.toLower(guest.name), _.toLower(filterText));
        });
      })

    const thead = (
      <tr>
        <th style={{ minWidth: 60 }}>Save</th>
        <th>Id</th>
        <th style={{ minWidth: 300 }}>Name</th>
        <th style={{ minWidth: 140 }}>RSVP</th>
        {!simpleView && <th style={{ minWidth: 140 }}>Side</th>}
        {!simpleView && <th style={{ minWidth: 140 }}>Status</th>}
        <th style={{ minWidth: 60 }}>Save</th>
      </tr>
    );

      const sorted = _.sortBy(filteredGuests, ({ name }) => _.toLower(name));

      return (
            <Layout>
              <input className="form-control my-3" placeholder="Search..." value={filterText || ''} onChange={(e) => this.setState({ filterText: e.target.value })} />

              <div className="row">
                <div className="col-12" style={{ marginTop: 5, marginBottom: 5 }}>
                  <div className="float-left">
                    <div className="status-badges">
                      <label>Side</label>
                      {
                        _.map(GuestSideOptions, ({ text, value, quickFilterKey, applies }) => {
                          const num = _.filter(sorted, applies).length;

                          const isActive = quickFilters[quickFilterKey] === true;

                          return (
                            <Button key={value} size="sm" color={isActive ? 'primary' : 'secondary'} onClick={() => this.setState({ quickFilters: { ...quickFilters, [quickFilterKey]: !quickFilters[quickFilterKey] } })}>{text} {num}</Button>
                          )
                        })
                      }

                      <label>Status</label>
                      {
                        _.map(GuestStatusOptions, ({ text, value, quickFilterKey, applies }) => {
                          const num = _.filter(sorted, applies).length;

                          const isActive = quickFilters[quickFilterKey] === true;

                          return (
                            <Button key={value} size="sm" color={isActive ? 'primary' : 'secondary'} onClick={() => this.setState({ quickFilters: { ...quickFilters, [quickFilterKey]: !quickFilters[quickFilterKey] } })}>{text} {num}</Button>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="float-right">
                    <div className="d-flex">
                      <label>Showing {_.size(sorted)} Guests</label>

                      <div className="form-check" style={{ marginLeft: 10 }}>
                        <input className="form-check-input" type="checkbox" checked={simpleView} onChange={() => this.setState({ simpleView: !this.state.simpleView })} id="defaultCheck1"/>
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          Simplified
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div style={{ overflowX: 'auto' }}>
                    <table className="table table-bordered table-striped table-hover table-sm">
                    <thead>
                      {thead}
                    </thead>
                    <tbody>
                    {
                      _.map(sorted.concat(baseNewGuest), (guest, i) => {
                        const isDirty = _.has(currentUpdates, guest.guestId);

                        const mergedGuest = isDirty ? { ...guest, ...currentUpdates[guest.guestId] } : guest;

                        return (
                          <React.Fragment key={guest.guestId}>
                            <tr>
                              <td>
                                {isDirty && <>
                                  <button className="btn btn-sm btn-primary" onClick={() => this.saveGuest(guest.guestId)}>Save</button>
                                </>}
                              </td>
                              <td>{mergedGuest.guestId}</td>
                              <td>
                                <input className="form-control input-sm" value={mergedGuest.name} onChange={(e) => this.setGuestName(guest, e)}/>
                              </td>
                              <td>
                                <ButtonGroup
                                  baseClassName="btn btn-sm"
                                  defaultClassName="btn-secondary"
                                  primaryClassName="btn-primary"
                                  options={RsvpStatusOptions}
                                  onClickData={mergedGuest}
                                  onChange={this.setRsvpStatus}
                                  value={mergedGuest.rsvpStatus}>
                                </ButtonGroup>
                              </td>
                              {!simpleView && <td>
                                <ButtonGroup
                                  baseClassName="btn btn-sm"
                                  defaultClassName="btn-secondary"
                                  primaryClassName="btn-primary"
                                  options={GuestSideOptions}
                                  onClickData={mergedGuest}
                                  onChange={this.setGuestSide}
                                  value={mergedGuest.side}>
                                </ButtonGroup>
                              </td>}
                              {!simpleView && <td>
                                <ButtonGroup
                                  baseClassName="btn btn-sm"
                                  defaultClassName="btn-secondary"
                                  primaryClassName="btn-primary"
                                  options={GuestStatusOptions}
                                  onChange={this.setGuestStatus}
                                  onClickData={mergedGuest}
                                  value={mergedGuest.status}>
                                </ButtonGroup>
                              </td>}
                              <td>
                                {isDirty && <>
                                  <button className="btn btn-sm btn-primary" onClick={() => this.saveGuest(guest.guestId)}>Save</button>
                                </>}
                              </td>
                            </tr>
                            {i > 0 && i % 9 === 0 &&
                            thead
                            }
                          </React.Fragment>
                        );
                      })
                    }
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
          </Layout>
      )
    }

    componentDidMount() {
      document.title = 'Guests';
      this.props.clearChanges();
    }

  setGuestName = (guest, e) => {
    this.props.setCurrentUpdates(guest.guestId, { name: e.target.value });
  };

  setGuestUsername = (guest, e) => {
    this.props.setCurrentUpdates(guest.guestId, { username: e.target.value });
  };

  setGuestPassword = (guest, e) => {
    this.props.setCurrentUpdates(guest.guestId, { password: e.target.value });
  };

  setGuestSide = (guest, side) => {
    this.props.setCurrentUpdates(guest.guestId, { side });
  };

  setRsvpStatus = (guest, rsvpStatus) => {
    this.props.setCurrentUpdates(guest.guestId, { rsvpStatus });
  };

  setGuestStatus = (guest, status) => {
    this.props.setCurrentUpdates(guest.guestId, { status });
  };

  saveGuest = (guestId) => {
    const { guests, currentUpdates } = this.props;

    const isDirty = _.has(currentUpdates, guestId);
    const guest = _.find(guests, { guestId })

    if(!isDirty) {
      return;
    }

    const mergedGuest = { ...baseNewGuest, ...(guest || {}), ...currentUpdates[guestId] };
    console.log({ mergedGuest })
    this.props.saveGuest(mergedGuest);
  }
}

const mapStateToProps = ({ guests, currentUpdates }) => {
  return {
    guests: guests,
    currentUpdates: currentUpdates,
  };
};

const mapDispatchToProps = {
  clearChanges: clearChanges,
  saveGuest: saveGuest,
  setCurrentUpdates: setCurrentUpdates,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestsPage);
