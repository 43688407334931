import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

class Button extends React.PureComponent {
    render(){
        const { onClickData, children, stopPropagation, ...rest } = this.props;
        return(
            <button {...rest} onClick={this.onClick}>
                {children}
            </button>
        );
    }

    onClick = (e) => {
    	if(this.props.stopPropagation) {
    		e.preventDefault();
    		e.stopPropagation();
			}
        if(!_.isFunction(this.props.onClick))
            return;

        const { onClickData } = this.props;

        this.props.onClick(onClickData, e);
    }
}

Button.propTypes = {
    onClickData: PropTypes.any,
    onClick: PropTypes.func,
};

export default Button;
